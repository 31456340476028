import PropTypes from 'prop-types'
import { css } from 'styled-components'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'
import DurationDropDown from 'mgr/lib/components/DurationDropDown'
import TimeDropDown from 'mgr/lib/components/TimeDropDown'
import { FONT_OPTIONS } from 'mgr/lib/utils/Constants'
import { getDurationDisplay, formattedTimesByMinuteDuration } from 'svr/common/TimeUtil'
import RadioList from 'svr/component-lib/Generic/RadioButtons/RadioList'
import Button from '../../../../common/Button'
import ColorPicker from '../../../../common/ColorPicker'
import { Select, CheckBox, Input } from '../../../../common/FormElement'
import Switch from '../../../../common/Switch'
import * as styles from '../assets/styles'
import BackgroundCropper from '../containers/BackgroundCropper'
import BackgroundImageUpload from '../containers/BackgroundImageUpload'
import HeaderCropper from '../containers/HeaderCropper'
import HeaderImageUpload from '../containers/HeaderImageUpload'
import Tooltip from 'svr/component-lib/Generic/Tooltip'
import { StyledVmsIconS, VmsIcons } from 'svr/common/VmsIcons'

const TIMES = formattedTimesByMinuteDuration(15)
const TIMES_IDX_ARRAY = Array.from({ length: TIMES.length }, (_, i) => i)

const MAX_SEARCH_RESULT = 48
const MIN_SEARCH_RESULT = 8

const PRIOR_OPTIONS = {
  DAYS: 'Days prior',
  DISABLED: 'Disabled',
}

const REDEMPTION_SYSTEMS = {
  VALUTEC: 'VALUTEC',
}

const VerticalContainer = {
  display: 'flex',
  flexDirection: 'column',
}

const SwitchContainer = {
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '10px',
}

const SelectsContainer = {
  display: 'flex',
  marginLeft: '30px',
  marginTop: '10px',
}

const SelectHeader = { ...styles.Note, margin: 0 }

const _isValidWidgetSettings = errorsDict => {
  for (const key in errorsDict) {
    if (errorsDict[key]) {
      return false
    }
  }
  return true
}

const _save = (actions, appState, widgetSettings) => {
  const extend = {
    availabilityTimeRange: widgetSettings.availabilityTimeRange,
    firstSeating: widgetSettings.firstSeating,
    lastSeating: widgetSettings.lastSeating,
    specificTimeSlots: widgetSettings.specificTimeSlots,
  }

  if (_isValidWidgetSettings(appState.errors)) {
    if (widgetSettings.availabilityTimeRange === 'customTimeRange') {
      // if they didn't set both a firstseating and lastseating, set the availability to All
      if (!widgetSettings.firstSeating || !widgetSettings.lastSeating) {
        actions.updateField('availabilityTimeRange', 'all')
        extend.availabilityTimeRange = 'all'
      }

      actions.updateField('specificTimeSlots', [])
      extend.specificTimeSlots = []
    } else if (widgetSettings.availabilityTimeRange === 'specificTimeSlots') {
      if (!widgetSettings.specificTimeSlots || widgetSettings.specificTimeSlots.length === 0) {
        actions.updateField('availabilityTimeRange', 'all')
        extend.availabilityTimeRange = 'all'
      }

      actions.updateField('firstSeating', null)
      actions.updateField('lastSeating', null)
      extend.firstSeating = null
      extend.lastSeating = null
    }

    if (extend.availabilityTimeRange === 'all') {
      actions.updateField('firstSeating', null)
      actions.updateField('lastSeating', null)
      actions.updateField('specificTimeSlots', [])
      extend.firstSeating = null
      extend.lastSeating = null
      extend.specificTimeSlots = []
    }

    actions.tryPostWidgetSettings({ ...widgetSettings, ...extend })
  }
}

const _changeField = (actions, fieldName, e) => {
  if (fieldName === 'font') {
    actions.updateField(fieldName, FONT_OPTIONS[e.target.value])
  } else {
    actions.updateField(fieldName, e.target.value)
  }
}

const _changeRadioField = (actions, val, fieldName, e) => {
  e.preventDefault()
  actions.updateField(fieldName, val)
}

const _changeFieldWithValue = (actions, _widgetSettings, fieldName, val) => {
  actions.updateField(fieldName, val)

  // special case for changing themes
  if (fieldName === 'widgetTemplateTheme') {
    actions.updateTheme()
  }
  if (fieldName === 'tailoredCommunicationOn' && val) {
    actions.updateField('isTailoredCommunicationPolicyDefaultOn', true)
  }
}

const _updateCustomTimeRange = (actions, widgetSettings, fieldName, times, val) => {
  const timeVal = times[val]

  if (fieldName === 'firstSeating') {
    if (times.indexOf(widgetSettings.lastSeating) < val) {
      actions.updateField('lastSeating', timeVal)
    }
  } else if (times.indexOf(widgetSettings.firstSeating) > val) {
    actions.updateField('firstSeating', timeVal)
  }

  actions.updateField(fieldName, timeVal)
}

const _changeSpecificTimeSlotsWithValue = (actions, widgetSettings, fieldName, times, vals) => {
  actions.updateField(
    fieldName,
    vals.map(timeKey => times[timeKey])
  )
}

const _changeHexColor = (actions, fieldName, color) => {
  actions.updateField(fieldName, color.hex)
}

const _changeColor = (actions, fieldName, fieldOpacityName, color) => {
  actions.updateField(fieldName, color.hex)
  actions.updateField(fieldOpacityName, color.rgb.a)
}

const getCopyCode = (widgetSettings, venue) => {
  const type = widgetSettings.buttonId % 2 === 1 ? 'dark' : 'light'
  let size
  if (widgetSettings.buttonId < 3) {
    size = 'sm'
  } else if (widgetSettings.buttonId < 5) {
    size = 'md'
  } else {
    size = 'lg'
  }
  const color = `sr-${widgetSettings.colorButton}`

  return [
    `<div id="sr-res-root" class="sr-${size} sr-${type} ${color}">${widgetSettings.textButton}</div>`,
    `<script src="https://${window.location.host}/widget/embed.js"></script>`,
    '<script>',
    'SevenroomsWidget.init({',
    `    venueId: "${venue}",`,
    `    triggerId: "sr-res-root", // id of the dom element that will trigger this widget`,
    `    type: "reservations", // either 'reservations' or 'waitlist' or 'events'`,
    `    styleButton: true, // true if you are using the SevenRooms button`,
    `    clientToken: "" //(Optional) Pass the api generated clientTokenId here`,
    '})',
    '</script>',
  ].join('\n')
}

const getLinkText = venue => `${window.location.host}/reservations/${venue}`

const capitalizeFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()

function TextEntry({ name, label, widgetSettings, appState, actions, width = 150, marginLeft = 0 }) {
  const _css = {
    width: `${width}px`,
    marginBottom: '5px',
  }

  const _value = widgetSettings[name]

  return (
    <div style={{ marginLeft: `${marginLeft}px` }}>
      <p className="group-label">{label}</p>
      <Input
        inputCss={_css}
        value={_value}
        onChangeHandler={_changeField.bind(this, actions, name)}
        disabled={appState.isGetting || appState.isPosting}
      />
      <div className="float-end" />
    </div>
  )
}

const getMaxSearchResults = value => {
  if (value > MAX_SEARCH_RESULT) {
    return MAX_SEARCH_RESULT
  }
  return value
}

export function WidgetSettingsForm({ MEDIA_URL, widgetSettings, appState, actions }) {
  const lightThemeImg = `${MEDIA_URL}images/eventmanager/template_light_2x.png`
  const darkThemeImg = `${MEDIA_URL}images/eventmanager/template_dark_2x.png`
  const venue = window.location.pathname.split('/')[2]

  return (
    <form style={styles.Form} className="styled" data-sr-validate onSubmit={e => e.preventDefault()}>
      <div style={styles.FormSection}>
        <div style={styles.SubHeader}>General</div>
        <div style={styles.MiniSubHeader}>Color scheme</div>
        <p style={styles.Note}>Choose a template to start your booking widget</p>

        <div className="artificial-exclusion">
          <div style={styles.ThemeSwitch}>
            <CheckBox
              name="widget_template_theme"
              labelText="Light"
              inputCss={styles.RadioCheckBox}
              onClickHandler={_changeFieldWithValue.bind(this, actions, widgetSettings, 'widgetTemplateTheme', 'LIGHT')}
              disabled={appState.isGetting || appState.isPosting}
              parentClass="radio-style"
              checked={widgetSettings.widgetTemplateTheme === 'LIGHT'}
            />
          </div>
          <div style={styles.ThemeSwitch}>
            <CheckBox
              name="widget_template_theme"
              labelText="Dark"
              inputCss={styles.RadioCheckBox}
              onClickHandler={_changeFieldWithValue.bind(this, actions, widgetSettings, 'widgetTemplateTheme', 'DARK')}
              disabled={appState.isGetting || appState.isPosting}
              parentClass="radio-style"
              checked={widgetSettings.widgetTemplateTheme === 'DARK'}
            />
          </div>
        </div>
        <div style={styles.ThemePreview}>
          {widgetSettings.widgetTemplateTheme === 'DARK' ? (
            <img src={darkThemeImg} style={styles.TemplateImage} alt="Dark Theme Example" />
          ) : (
            <img src={lightThemeImg} style={styles.TemplateImage} alt="Light Theme Example" />
          )}
        </div>
        <div className="float-end" />

        <Select
          name="font"
          labelText="Font"
          value={FONT_OPTIONS.indexOf(widgetSettings.font)}
          inputCss={styles.Select}
          disabled={appState.isGetting || appState.isPosting}
          options={FONT_OPTIONS}
          MEDIA_URL={MEDIA_URL}
          onChangeHandler={_changeField.bind(this, actions, 'font')}
        />

        <div style={{ ...styles.MiniSubHeader, paddingTop: '20px' }}>Main colors</div>

        <div style={styles.SettingRow}>
          <div style={styles.SettingColTitle}>
            <p className="group-label">Primary color</p>
          </div>
          <div style={styles.SettingCol}>
            <ColorPicker
              color={{ hex: widgetSettings.colorPrimary, opacity: 1.0 }}
              onChangeColor={_changeHexColor.bind(this, actions, 'colorPrimary')}
            />
          </div>
        </div>

        <div style={styles.SettingRow}>
          <div style={styles.SettingColTitle}>
            <p className="group-label">Summary bar color</p>
          </div>
          <div style={styles.SettingCol}>
            <ColorPicker
              color={{ hex: widgetSettings.colorSummaryBar, opacity: 1.0 }}
              onChangeColor={_changeHexColor.bind(this, actions, 'colorSummaryBar')}
            />
          </div>
        </div>

        <div style={styles.SettingRow}>
          <div style={styles.SettingColTitle}>
            <p className="group-label">Page background</p>
          </div>
          <div style={styles.SettingCol}>
            <ColorPicker
              color={{
                hex: widgetSettings.colorBackground,
                opacity: widgetSettings.colorBackgroundOpacity,
              }}
              disableAlpha={false}
              onChangeColor={_changeColor.bind(this, actions, 'colorBackground', 'colorBackgroundOpacity')}
            />
          </div>
        </div>

        <div style={styles.MiniSubHeader}>Header image</div>
        <HeaderImageUpload
          imageType="header"
          url={widgetSettings.headerImgUrl}
          cropData={widgetSettings.headerImgCropData}
          style={{ width: '490px', height: '210px', marginBottom: '20px' }}
        />
        {widgetSettings.isCropping === 'header' && (
          <HeaderCropper imageType="header" url={widgetSettings.headerImgUrl} cropData={widgetSettings.headerImgCropData} />
        )}

        <div style={styles.MiniSubHeader}>Background image</div>
        <BackgroundImageUpload
          imageType="background"
          url={widgetSettings.backgroundImgUrl}
          cropData={widgetSettings.backgroundImgCropData}
          style={{ width: '490px', height: '210px' }}
        />
        {widgetSettings.isCropping === 'background' && (
          <BackgroundCropper imageType="background" url={widgetSettings.backgroundImgUrl} cropData={widgetSettings.backgroundImgCropData} />
        )}

        <div className="inline">
          <Button style={styles.SaveButton} label="Save" onClick={_save.bind(this, actions, appState, widgetSettings)} />
        </div>
        <div className="float-end" />
      </div>

      <div style={styles.FormSection}>
        <div style={styles.SubHeader}>Advanced settings</div>
        <div style={styles.RestoreDefault} onClick={actions.restoreDefault}>
          Restore defaults
        </div>
        <p style={{ ...styles.Note, marginTop: 32 }}>This will restore all the advanced settings to the template selected above</p>
        <div className="float-end" />
        <div style={styles.MiniSubHeader}>Fonts</div>
        <div style={styles.SettingRow}>
          <div style={styles.SettingColTitle}>
            <p className="group-label">Primary</p>
          </div>
          <div style={styles.SettingCol}>
            <ColorPicker
              color={{ hex: widgetSettings.fontsColorPrimary, opacity: 1.0 }}
              onChangeColor={_changeHexColor.bind(this, actions, 'fontsColorPrimary')}
            />
          </div>
        </div>
        <div style={styles.SettingRow}>
          <div style={styles.SettingColTitle}>
            <p className="group-label">Secondary</p>
          </div>
          <div style={styles.SettingCol}>
            <ColorPicker
              color={{ hex: widgetSettings.fontsColorSecondary, opacity: 1.0 }}
              onChangeColor={_changeHexColor.bind(this, actions, 'fontsColorSecondary')}
            />
          </div>
        </div>
        <div style={styles.SettingRow}>
          <div style={styles.SettingColTitle}>
            <p className="group-label">Summary bar</p>
          </div>
          <div style={styles.SettingCol}>
            <ColorPicker
              color={{ hex: widgetSettings.fontsColorSummaryBar, opacity: 1.0 }}
              onChangeColor={_changeHexColor.bind(this, actions, 'fontsColorSummaryBar')}
            />
          </div>
        </div>
        <div style={styles.SettingRow}>
          <div style={styles.SettingColTitle}>
            <p className="group-label">Button</p>
          </div>
          <div style={styles.SettingCol}>
            <ColorPicker
              color={{ hex: widgetSettings.fontsColorButton, opacity: 1.0 }}
              onChangeColor={_changeHexColor.bind(this, actions, 'fontsColorButton')}
            />
          </div>
        </div>
        <div style={styles.SettingRow}>
          <div style={styles.SettingColTitle}>
            <p className="group-label">Action bar</p>
          </div>
          <div style={styles.SettingCol}>
            <ColorPicker
              color={{ hex: widgetSettings.fontsColorActionBar, opacity: 1.0 }}
              onChangeColor={_changeHexColor.bind(this, actions, 'fontsColorActionBar')}
            />
          </div>
        </div>
        <div style={styles.SettingRow}>
          <div style={styles.SettingColTitle}>
            <p className="group-label">Links</p>
          </div>
          <div style={styles.SettingCol}>
            <ColorPicker
              color={{ hex: widgetSettings.fontsColorLinks, opacity: 1.0 }}
              onChangeColor={_changeHexColor.bind(this, actions, 'fontsColorLinks')}
            />
          </div>
        </div>
        <div style={styles.SettingRow}>
          <div style={styles.SettingColTitle}>
            <p className="group-label">Checkout inactive</p>
          </div>
          <div style={styles.SettingCol}>
            <ColorPicker
              color={{
                hex: widgetSettings.fontsColorCheckoutInactive,
                opacity: 1.0,
              }}
              onChangeColor={_changeHexColor.bind(this, actions, 'fontsColorCheckoutInactive')}
            />
          </div>
        </div>
        <div style={styles.SettingRow}>
          <div style={styles.SettingColTitle}>
            <p className="group-label">Checkout active</p>
          </div>
          <div style={styles.SettingCol}>
            <ColorPicker
              color={{
                hex: widgetSettings.fontsColorCheckoutActive,
                opacity: 1.0,
              }}
              onChangeColor={_changeHexColor.bind(this, actions, 'fontsColorCheckoutActive')}
            />
          </div>
        </div>
        <div style={styles.MiniSubHeader}>Styles</div>
        <div style={styles.SettingRow}>
          <div style={styles.SettingColTitle}>
            <p className="group-label">Widget background</p>
          </div>
          <div style={styles.SettingCol}>
            <ColorPicker
              color={{
                hex: widgetSettings.colorWidgetBackground,
                opacity: widgetSettings.colorWidgetBackgroundOpacity,
              }}
              disableAlpha={false}
              onChangeColor={_changeColor.bind(this, actions, 'colorWidgetBackground', 'colorWidgetBackgroundOpacity')}
            />
          </div>
        </div>
        <div style={styles.SettingRow}>
          <div style={styles.SettingColTitle}>
            <p className="group-label">Cell background</p>
          </div>
          <div style={styles.SettingCol}>
            <ColorPicker
              color={{
                hex: widgetSettings.colorCellBackground,
                opacity: widgetSettings.colorCellBackgroundOpacity,
              }}
              disableAlpha={false}
              onChangeColor={_changeColor.bind(this, actions, 'colorCellBackground', 'colorCellBackgroundOpacity')}
            />
          </div>
        </div>
        <div style={styles.SettingRow}>
          <div style={styles.SettingColTitle}>
            <p className="group-label">Action bar background</p>
          </div>
          <div style={styles.SettingCol}>
            <ColorPicker
              color={{
                hex: widgetSettings.colorActionBarBackground,
                opacity: widgetSettings.colorActionBarBackgroundOpacity,
              }}
              disableAlpha={false}
              onChangeColor={_changeColor.bind(this, actions, 'colorActionBarBackground', 'colorActionBarBackgroundOpacity')}
            />
          </div>
        </div>
        <div style={styles.SettingRow}>
          <div style={styles.SettingColTitle}>
            <p className="group-label">Checkout cell background</p>
          </div>
          <div style={styles.SettingCol}>
            <ColorPicker
              color={{
                hex: widgetSettings.colorCheckoutCellBackground,
                opacity: widgetSettings.colorCheckoutCellBackgroundOpacity,
              }}
              disableAlpha={false}
              onChangeColor={_changeColor.bind(this, actions, 'colorCheckoutCellBackground', 'colorCheckoutCellBackgroundOpacity')}
            />
          </div>
        </div>
        <div style={styles.SettingRow}>
          <div style={styles.SettingColTitle}>
            <p className="group-label">Lines</p>
          </div>
          <div style={styles.SettingCol}>
            <ColorPicker
              color={{
                hex: widgetSettings.colorLines,
                opacity: widgetSettings.colorLinesOpacity,
              }}
              disableAlpha={false}
              onChangeColor={_changeColor.bind(this, actions, 'colorLines', 'colorLinesOpacity')}
            />
          </div>
        </div>
        <div style={styles.SettingRow}>
          <div style={styles.SettingColTitle}>
            <p className="group-label">Error</p>
          </div>
          <div style={styles.SettingCol}>
            <ColorPicker
              color={{
                hex: widgetSettings.colorError,
                opacity: widgetSettings.colorErrorOpacity,
              }}
              onChangeColor={_changeHexColor.bind(this, actions, 'colorError')}
            />
          </div>
        </div>
        <div className="float-end" />
        <div className="inline">
          <Button style={styles.SaveButton} label="Save" onClick={_save.bind(this, actions, appState, widgetSettings)} />
        </div>
        <div className="float-end" />
      </div>

      <div style={styles.FormSection}>
        <div style={styles.SubHeader}>Widget button settings</div>

        <p className="group-label">Customize text on button</p>
        <Input
          name="custom_button_text"
          inputCss={{ marginBottom: '10px', width: '299px' }}
          value={widgetSettings.textButton}
          onChangeHandler={_changeField.bind(this, actions, 'textButton')}
          disabled={appState.isGetting || appState.isPosting}
        />
        <div className="float-end" />
        <div style={styles.SettingRow}>
          <div style={styles.SettingColTitle}>
            <p className="group-label">Characters</p>
          </div>
          <div style={styles.SettingCol} className="radioset">
            {widgetSettings.textButton !== widgetSettings.textButton.toUpperCase() ? (
              <div className="inline" style={{ marginBottom: '5px' }}>
                <div
                  style={styles.CharBoxFirst}
                  onClick={_changeFieldWithValue.bind(this, actions, widgetSettings, 'textButton', widgetSettings.textButton.toUpperCase())}
                >
                  AA
                </div>
                <div style={{ ...styles.CharBoxLast, ...styles.BlueCharBox }}>Aa</div>
              </div>
            ) : (
              <div className="inline" style={{ marginBottom: '5px' }}>
                <div style={{ ...styles.CharBoxFirst, ...styles.BlueCharBox }}>AA</div>
                <div
                  style={styles.CharBoxLast}
                  onClick={_changeFieldWithValue.bind(
                    this,
                    actions,
                    widgetSettings,
                    'textButton',
                    capitalizeFirstLetter(widgetSettings.textButton)
                  )}
                >
                  Aa
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="float-end" />
        <div style={styles.SettingRow}>
          <div style={styles.SettingColTitle}>
            <p className="group-label">Button color</p>
          </div>
          <div style={styles.SettingCol}>
            <ColorPicker
              color={{ hex: widgetSettings.colorButton, opacity: 1.0 }}
              onChangeColor={_changeHexColor.bind(this, actions, 'colorButton')}
            />
          </div>
        </div>
        <div className="float-end" />

        <p className="group-label" style={{ margin: '13px 0 7px' }}>
          Select which button you would like to use on your website
        </p>
        <div style={styles.SettingRow}>
          <div style={styles.SettingCol}>
            <div
              style={styles.SelectButtonStyle(1, widgetSettings.buttonId)}
              onClick={_changeFieldWithValue.bind(this, actions, widgetSettings, 'buttonId', 1)}
            >
              <div style={styles.ButtonStyle('small', 'dark', widgetSettings.colorButton)}>
                <i style={styles.ButtonImg('small')} className="sr-fa sr-fa-logo" />
                <span style={{ ...styles.ButtonText, maxWidth: '87px' }}>{widgetSettings.textButton}</span>
              </div>
            </div>
          </div>
          <div style={styles.SettingCol}>
            <div
              style={styles.SelectButtonStyle(2, widgetSettings.buttonId)}
              onClick={_changeFieldWithValue.bind(this, actions, widgetSettings, 'buttonId', 2)}
            >
              <div style={styles.ButtonStyle('small', 'light', widgetSettings.colorButton)}>
                <i style={styles.ButtonImg('small')} className="sr-fa sr-fa-logo" />
                <span style={{ ...styles.ButtonText, maxWidth: '87px' }}>{widgetSettings.textButton}</span>
              </div>
            </div>
          </div>
        </div>
        <div style={styles.SettingRow}>
          <div style={styles.SettingCol}>
            <div
              style={styles.SelectButtonStyle(3, widgetSettings.buttonId)}
              onClick={_changeFieldWithValue.bind(this, actions, widgetSettings, 'buttonId', 3)}
            >
              <div style={styles.ButtonStyle('medium', 'dark', widgetSettings.colorButton)}>
                <i style={styles.ButtonImg('medium')} className="sr-fa sr-fa-logo" />
                <span style={{ ...styles.ButtonText, maxWidth: '100px' }}>{widgetSettings.textButton}</span>
              </div>
            </div>
          </div>
          <div style={styles.SettingCol}>
            <div
              style={styles.SelectButtonStyle(4, widgetSettings.buttonId)}
              onClick={_changeFieldWithValue.bind(this, actions, widgetSettings, 'buttonId', 4)}
            >
              <div style={styles.ButtonStyle('medium', 'light', widgetSettings.colorButton)}>
                <i style={styles.ButtonImg('medium')} className="sr-fa sr-fa-logo" />
                <span style={{ ...styles.ButtonText, maxWidth: '100px' }}>{widgetSettings.textButton}</span>
              </div>
            </div>
          </div>
        </div>
        <div style={styles.SettingRow}>
          <div style={styles.SettingCol}>
            <div
              style={styles.SelectButtonStyle(5, widgetSettings.buttonId)}
              onClick={_changeFieldWithValue.bind(this, actions, widgetSettings, 'buttonId', 5)}
            >
              <div style={styles.ButtonStyle('large', 'dark', widgetSettings.colorButton)}>
                <i style={styles.ButtonImg('large')} className="sr-fa sr-fa-logo" />
                <span style={{ ...styles.ButtonText, maxWidth: '130px' }}>{widgetSettings.textButton}</span>
              </div>
            </div>
          </div>
          <div style={styles.SettingCol}>
            <div
              style={styles.SelectButtonStyle(6, widgetSettings.buttonId)}
              onClick={_changeFieldWithValue.bind(this, actions, widgetSettings, 'buttonId', 6)}
            >
              <div style={styles.ButtonStyle('large', 'light', widgetSettings.colorButton)}>
                <i style={styles.ButtonImg('large')} className="sr-fa sr-fa-logo" />
                <span style={{ ...styles.ButtonText, maxWidth: '130px' }}>{widgetSettings.textButton}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="inline">
          <p style={styles.Note}>
            In order for the selected widget button settings to take effect,
            <br />
            you will need to be sure the snippet below matches the snippet on your website.
          </p>
        </div>
        <div className="float-end" />

        <div style={{ ...styles.MiniSubHeader, margin: '22px 0 8px' }}>Links</div>
        <p className="group-label">Copy and paste this code into your website</p>
        <textarea style={{ width: '540px', height: '161px', resize: 'none' }} readOnly value={getCopyCode(widgetSettings, venue)} />
        <p className="group-label">Direct link to widget</p>
        <div className="inline">
          <textarea style={{ width: '450px', height: '17px', resize: 'none' }} readOnly value={getLinkText(venue)} />
        </div>
        <div className="float-end" />
      </div>

      <div style={styles.FormSection}>
        <div style={styles.SubHeader}>Customized text</div>

        {[
          {
            name: 'textCustomPrivacyPolicyLink',
            label: 'Custom Privacy Policy Link',
            width: '300',
          },
          {
            name: 'textCustomGdprPolicyLink',
            label: 'Custom GDPR Policy Link',
            width: '300',
          },
          {
            name: 'textCustomPolicyHolderName',
            label: 'Policy Holder Name (if Custom Privacy Policy Link provided)',
            width: '300',
          },
        ].map((item, index) => {
          if (item.header) {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`h_${index}`} style={{ ...styles.MiniSubHeader, paddingTop: 20 }}>
                {item.header}
              </div>
            )
          }
          return (
            <TextEntry
              name={item.name}
              label={item.label}
              key={item.name}
              width={item.width}
              marginLeft={item.marginLeft}
              widgetSettings={widgetSettings}
              appState={appState}
              actions={actions}
            />
          )
        })}
      </div>

      <div style={styles.FormSection}>
        <div style={styles.SubHeader}>Search Configurations</div>

        <div style={styles.MiniSubHeader}>Default Search Time</div>

        <div style={SelectHeader}>
          Default time for initial search (if current local time is later than default search time set below, current local time will be
          used instead so that customers do not search in the past)
        </div>

        <TimeDropDown
          startOfDayHour={0}
          noHeader
          timeSlot={TIMES.indexOf(widgetSettings.defaultSearchTime?.replace(' ', ''))}
          timeSlots={TIMES_IDX_ARRAY}
          onChangeTime={val => _changeFieldWithValue(actions, widgetSettings, 'defaultSearchTime', TIMES[val])}
          disabled={appState.isGetting || appState.isPosting}
          showNavigation={false}
          isLightTheme
          width={244}
          style={{ marginTop: '10px', marginBottom: '30px' }}
        />

        <div style={styles.MiniSubHeader}>Calendar Search Length</div>

        <div style={SelectHeader}>
          This number determines how far out the user is able to select available dates on the widget's calendar. Max: 999. Min: 1.
        </div>

        <Input
          inputCss={{ marginTop: 20, marginBottom: 20, width: 60 }}
          type="number"
          value={widgetSettings.maxDaysOut}
          min={1}
          max={999}
          onChangeHandler={event =>
            _changeFieldWithValue(actions, widgetSettings, 'maxDaysOut', Math.min(999, Math.max(1, Number(event.target.value))))
          }
        />

        <div style={styles.MiniSubHeader}>Availability Time Range</div>

        <div style={VerticalContainer}>
          <div style={SwitchContainer}>
            <CheckBox
              name="availability_time_range_all"
              labelText="All times"
              inputCss={styles.RadioCheckBox}
              onClickHandler={_changeFieldWithValue.bind(this, actions, widgetSettings, 'availabilityTimeRange', 'all')}
              disabled={appState.isGetting || appState.isPosting}
              parentClass="radio-style"
              checked={widgetSettings.availabilityTimeRange === 'all'}
            />
          </div>
          <div style={SwitchContainer}>
            <CheckBox
              name="availability_time_range_custom"
              labelText="Custom time range"
              tip="Please note that the new reservation widget lists 30 minute increments by default,<br /> so First Seating and Last Seating will be rounded to the nearest :00 or :30 time increment"
              inputCss={styles.RadioCheckBox}
              onClickHandler={_changeFieldWithValue.bind(this, actions, widgetSettings, 'availabilityTimeRange', 'customTimeRange')}
              disabled={appState.isGetting || appState.isPosting}
              parentClass="radio-style"
              checked={widgetSettings.availabilityTimeRange === 'customTimeRange'}
            />

            {widgetSettings.availabilityTimeRange === 'customTimeRange' && (
              <div style={SelectsContainer}>
                <div style={VerticalContainer}>
                  <TimeDropDown
                    startOfDayHour={0}
                    timeSlot={TIMES.indexOf(widgetSettings.firstSeating)}
                    timeSlots={TIMES_IDX_ARRAY}
                    name="First Seating"
                    useOutsideLabel
                    onChangeTime={val => _updateCustomTimeRange(actions, widgetSettings, 'firstSeating', TIMES, val)}
                    disabled={appState.isGetting || appState.isPosting}
                    showNavigation={false}
                    isLightTheme
                    width={244}
                    style={{ marginTop: '10px' }}
                  />
                </div>

                <div style={{ ...VerticalContainer, marginLeft: '10px' }}>
                  <TimeDropDown
                    startOfDayHour={0}
                    timeSlot={TIMES.indexOf(widgetSettings.lastSeating)}
                    timeSlots={TIMES_IDX_ARRAY}
                    name="Last Seating"
                    useOutsideLabel
                    onChangeTime={val => _updateCustomTimeRange(actions, widgetSettings, 'lastSeating', TIMES, val)}
                    disabled={appState.isGetting || appState.isPosting}
                    showNavigation={false}
                    isLightTheme
                    width={244}
                    style={{ marginTop: '10px' }}
                  />
                </div>
              </div>
            )}
          </div>
          <div style={SwitchContainer}>
            <CheckBox
              name="availability_time_range_specific"
              labelText="Specific time slot(s)"
              inputCss={styles.RadioCheckBox}
              onClickHandler={_changeFieldWithValue.bind(this, actions, widgetSettings, 'availabilityTimeRange', 'specificTimeSlots')}
              disabled={appState.isGetting || appState.isPosting}
              parentClass="radio-style"
              checked={widgetSettings.availabilityTimeRange === 'specificTimeSlots'}
            />

            {widgetSettings.availabilityTimeRange === 'specificTimeSlots' && (
              <TimeDropDown
                startOfDayHour={0}
                isMultiSelect
                noHeader
                values={widgetSettings.specificTimeSlots.map(val => TIMES.indexOf(val))}
                timeSlots={TIMES_IDX_ARRAY}
                onChangeTime={change =>
                  _changeSpecificTimeSlotsWithValue(
                    actions,
                    widgetSettings,
                    'specificTimeSlots',
                    TIMES,
                    change.sort((a, b) => a - b)
                  )
                }
                disabled={appState.isGetting || appState.isPosting}
                showNavigation={false}
                isLightTheme
                width={244}
                style={{ marginLeft: '30px', marginTop: '10px' }}
              />
            )}
          </div>
        </div>

        <div style={styles.MiniSubHeader}>Duration Picker</div>

        <div style={SelectHeader}>Default duration for initial search if Widget has Duration picker enabled</div>
        <DurationDropDown
          duration={parseInt(widgetSettings.defaultSearchDuration, 10)}
          name="Default Duration"
          useOutsideLabel
          onChangeDuration={val => _changeFieldWithValue(actions, widgetSettings, 'defaultSearchDuration', `${val}`)}
          width={244}
          disabled={appState.isGetting || appState.isPosting}
          style={{ margin: '20px 0 0' }}
        />
        <div className="inline">
          <DurationDropDown
            duration={parseInt(widgetSettings.minimumSearchDuration, 10)}
            name="Minimum Duration"
            useOutsideLabel
            onChangeDuration={val => _changeFieldWithValue(actions, widgetSettings, 'minimumSearchDuration', `${val}`)}
            width={244}
            disabled={appState.isGetting || appState.isPosting}
            style={{ margin: '20px 0' }}
          />
          <DurationDropDown
            duration={parseInt(widgetSettings.maximumSearchDuration, 10)}
            name="Maximum Duration"
            useOutsideLabel
            onChangeDuration={val => _changeFieldWithValue(actions, widgetSettings, 'maximumSearchDuration', `${val}`)}
            width={244}
            disabled={appState.isGetting || appState.isPosting}
            style={{ margin: '20px' }}
          />
          <DropdownArrowsPicker
            key="DURATION_INTERVAL"
            name="Duration Interval"
            choices={[15, 30, 60].map(value => ({
              name: getDurationDisplay(value, false, false),
              value,
            }))}
            value={parseInt(widgetSettings.searchDurationInterval, 10)}
            onChangeHandler={val => _changeFieldWithValue(actions, widgetSettings, 'searchDurationInterval', `${val}`)}
            style={{
              float: 'left',
              width: 244,
              margin: '20px 0',
            }}
            showNavigation
            useOutsideLabel
            isLightTheme
          />
        </div>
        <div className="float-end" />

        <div style={styles.MiniSubHeader}>Additional Search Configurations</div>

        <div className="settings-height">
          <div className="settings-col settings-height">
            <span className="switch-label">Calendar view open by default</span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(this, actions, widgetSettings, 'calendarOpen', !widgetSettings.calendarOpen)}
              value={!widgetSettings.calendarOpen}
              style={{ margin: '7px 0' }}
            />
          </div>
        </div>
        <div className="settings-height">
          <div className="settings-col settings-height">
            <span className="switch-label">
              {widgetSettings.priorityAlertsEnabled ? 'Enable Requests and Priority Alerts' : 'Enable Requests'}
            </span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(this, actions, widgetSettings, 'enableRequests', !widgetSettings.enableRequests)}
              value={!widgetSettings.enableRequests}
              style={{ margin: '7px 0' }}
              disabled={widgetSettings.priorityAlertsEnabled}
              testId="widget-settings-enable-requests"
            />
          </div>
        </div>

        {widgetSettings.enableRequests && (
          <div style={{ paddingLeft: '20px' }}>
            <RadioList
              field="enableRequestsType"
              selectedValue={widgetSettings.enableRequestsType}
              onClick={_changeRadioField.bind(this, actions)}
              options={[
                { value: 'searched_time', label: 'Shift must exist at searched time' },
                {
                  value: 'active_booking_window',
                  label: 'Access Rules must be in active Booking Window at searched time',
                  tip: 'To ensure guests cannot make requests if availability for date/time has not yet been opened',
                  name: 'activeBookingWindow',
                },
              ]}
              customRadioListLabel={css`
                color: #aaa;
                font-size: 12px;
                font-weight: 700;
                font-family: Roboto, sans-serif;
                margin: 10px 0px 5px;
              `}
              customRadioIcon={css`
                width: 20px;
                height: 20px;
              `}
              customRadioLabel={css`
                font-weight: 400;
                line-height: 20px;
                color: #aaa;
                font-size: 14px;
                padding: 8px 0 0 4px;
              `}
              customRadioWrapper={css`
                margin-bottom: 4px;
              `}
              customRadioCheck={css`
                width: 10px;
                height: 10px;
                margin: 3px;
                background-color: #347baf;
              `}
            />
          </div>
        )}

        <div className="input-height">
          <div className="input-col input-height">
            <Input
              id="search_radius"
              labelText="Search Radius (Hours)"
              type="number"
              inputCss={{ width: 180 }}
              value={widgetSettings.searchHaloDurationHours}
              min={4}
              max={16}
              onChangeHandler={event =>
                _changeFieldWithValue(
                  actions,
                  widgetSettings,
                  'searchHaloDurationHours',
                  Math.min(16, Math.max(4, Number(event.target.value)))
                )
              }
              validator_type="int_positive"
              validator_message="Enter a number"
              tip="Min Value: 4 Hours, Max Value: 16 Hours"
              name="searchHaloDurationHours"
            />
          </div>
        </div>

        <div className="input-height">
          <div className="input-col input-height">
            <Input
              id="max_search_results"
              labelText="Max Number of Search Results"
              type="number"
              inputCss={{ width: 180 }}
              value={widgetSettings.maxSearchResults}
              min={8}
              max={48}
              onChangeHandler={event =>
                _changeFieldWithValue(
                  actions,
                  widgetSettings,
                  'maxSearchResults',
                  Math.max(MIN_SEARCH_RESULT, getMaxSearchResults(Number(event.target.value)))
                )
              }
              validator_type="int_positive"
              validator_message="Enter a number"
              tip="Min Value: 8, Max Value: 48"
              name="maxSearchResults"
            />
          </div>
        </div>

        <div className="input-height">
          <div className="input-col input-height">
            <Input
              id="min_number_guests"
              labelText="Min number of guests"
              type="number"
              inputCss={{ width: 30 }}
              value={widgetSettings.minGuests}
              min={1}
              max={99}
              onChangeHandler={event => {
                _changeFieldWithValue(actions, widgetSettings, 'minGuests', Number(event.target.value))
              }}
              onBlurHandler={event => {
                // eslint-disable-next-line no-param-reassign
                event.target.value = widgetSettings.minGuests
              }}
              validator_type="int_positive"
              validator_message="Enter a number"
            />
          </div>

          <div className="input-col input-height">
            <Input
              id="max_number_guests"
              labelText="Max number of guests"
              type="number"
              inputCss={{ width: 30 }}
              value={widgetSettings.maxGuests}
              min={1}
              max={99}
              onChangeHandler={event => {
                _changeFieldWithValue(actions, widgetSettings, 'maxGuests', Number(event.target.value))
              }}
              onBlurHandler={event => {
                // eslint-disable-next-line no-param-reassign
                event.target.value = widgetSettings.maxGuests
              }}
              validator_type="int_positive"
              validator_message="Enter a number"
            />
          </div>
        </div>

        <div className="input-height">
          <div className="input-col input-height">
            <Input
              id="allow_customers_book"
              labelText="Allow customers to book"
              type="number"
              inputCss={{ width: 30 }}
              value={widgetSettings.saleStartNum}
              min={1}
              disabled={widgetSettings.saleStartType === 'DISABLED'}
              onChangeHandler={_changeField.bind(this, actions, 'saleStartNum')}
              validator_type="int_positive"
              validator_message="Enter a number"
            />
          </div>
          <div className="input-col input-height input-select">
            <Select
              value={widgetSettings.saleStartType}
              options={PRIOR_OPTIONS}
              MEDIA_URL={MEDIA_URL}
              onChangeHandler={_changeField.bind(this, actions, 'saleStartType')}
            />
          </div>
        </div>
        <div className="settings-height">
          <div className="settings-col settings-height">
            <span className="switch-label">Display additional fees in price</span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="is_fees_in_price_displayed"
              toggle={_changeFieldWithValue.bind(
                this,
                actions,
                widgetSettings,
                'isFeesInPriceDisplayed',
                !widgetSettings.isFeesInPriceDisplayed
              )}
              value={!widgetSettings.isFeesInPriceDisplayed}
              style={{ margin: '7px 0' }}
            />
          </div>
        </div>
      </div>
      <div style={styles.FormSection}>
        <div style={styles.SubHeader}>Checkout & Confirmation Configurations</div>
        <div className="settings-height">
          <div className="settings-col settings-height">
            <span className="switch-label">Enable promo codes</span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(this, actions, widgetSettings, 'enablePromoCodes', !widgetSettings.enablePromoCodes)}
              value={!widgetSettings.enablePromoCodes}
              style={{ margin: '7px 0' }}
            />
          </div>
        </div>
        {Array.isArray(widgetSettings.redemptionSystemsEnabled) &&
          widgetSettings.redemptionSystemsEnabled.includes(REDEMPTION_SYSTEMS.VALUTEC) && (
            <div className="settings-height">
              <div className="settings-col settings-height">
                <span className="switch-label">Enable Gift Card Redemption</span>
              </div>
              <div className="settings-col settings-height">
                <Switch
                  name="hide_event"
                  toggle={_changeFieldWithValue.bind(
                    this,
                    actions,
                    widgetSettings,
                    'enableGiftCardRedemption',
                    !widgetSettings.enableGiftCardRedemption
                  )}
                  value={!widgetSettings.enableGiftCardRedemption}
                  style={{ margin: '7px 0' }}
                />
              </div>
            </div>
          )}
        <div className="settings-height">
          <div className="settings-col settings-height">
            <span className="switch-label">Enable social media login</span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(
                this,
                actions,
                widgetSettings,
                'enableSocialMediaLogin',
                !widgetSettings.enableSocialMediaLogin
              )}
              value={!widgetSettings.enableSocialMediaLogin}
              style={{ margin: '7px 0' }}
            />
          </div>
        </div>
        {widgetSettings.enableBonvoyWidgetLoginEnabled && (
          <div className="settings-height">
            <div className="settings-col settings-height">
              <span className="switch-label">Enable Bonvoy login</span>
            </div>
            <div className="settings-col settings-height">
              <Switch
                name="hide_event"
                toggle={_changeFieldWithValue.bind(this, actions, widgetSettings, 'enableBonvoyLogin', !widgetSettings.enableBonvoyLogin)}
                value={!widgetSettings.enableBonvoyLogin}
                style={{ margin: '7px 0' }}
              />
            </div>
          </div>
        )}
        <div className="settings-height">
          <div className="settings-col settings-height">
            <span className="switch-label">Ask for Dietary Restrictions on Checkout page</span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(
                this,
                actions,
                widgetSettings,
                'enableDietaryRestrictions',
                !widgetSettings.enableDietaryRestrictions
              )}
              value={!widgetSettings.enableDietaryRestrictions}
              style={{ margin: '7px 0' }}
            />
          </div>
        </div>
        <div className="settings-height">
          <div className="settings-col settings-height" style={{ lineHeight: 'unset' }}>
            <span className="switch-label">Mandate active consent for processing Dietary Restrictions on Checkout page</span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(
                this,
                actions,
                widgetSettings,
                'requireDietaryTagGdprOptIn',
                !widgetSettings.requireDietaryTagGdprOptIn
              )}
              value={!widgetSettings.requireDietaryTagGdprOptIn}
              style={{ margin: '7px 0' }}
            />
          </div>
        </div>
        <div className="settings-height">
          <div className="settings-col settings-height">
            <span className="switch-label">Ask if Special Occasion on Checkout page</span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(
                this,
                actions,
                widgetSettings,
                'enableSpecialOccasions',
                !widgetSettings.enableSpecialOccasions
              )}
              value={!widgetSettings.enableSpecialOccasions}
              style={{ margin: '7px 0' }}
            />
          </div>
        </div>
        <div className="settings-height">
          <div className="settings-col settings-height">
            <span className="switch-label">Ask for Dietary Restrictions on Confirmation page</span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(
                this,
                actions,
                widgetSettings,
                'enableDietaryRestrictionsConfirmation',
                !widgetSettings.enableDietaryRestrictionsConfirmation
              )}
              value={!widgetSettings.enableDietaryRestrictionsConfirmation}
              style={{ margin: '7px 0' }}
            />
          </div>
        </div>
        <div className="settings-height">
          <div className="settings-col settings-height" style={{ lineHeight: 'unset' }}>
            <span className="switch-label">Mandate active consent for processing Dietary Restrictions on Confirmation page</span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(
                this,
                actions,
                widgetSettings,
                'requireDietaryTagGdprOptInConfirmation',
                !widgetSettings.requireDietaryTagGdprOptInConfirmation
              )}
              value={!widgetSettings.requireDietaryTagGdprOptInConfirmation}
              style={{ margin: '7px 0' }}
            />
          </div>
        </div>
        <div className="settings-height">
          <div className="settings-col settings-height">
            <span className="switch-label">Ask if Special Occasion on Confirmation page</span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(
                this,
                actions,
                widgetSettings,
                'enableSpecialOccasionsConfirmation',
                !widgetSettings.enableSpecialOccasionsConfirmation
              )}
              value={!widgetSettings.enableSpecialOccasionsConfirmation}
              style={{ margin: '7px 0' }}
            />
          </div>
        </div>
        <div className="settings-height">
          <div className="settings-col settings-height" style={{ lineHeight: 'unset' }}>
            <span className="switch-label">
              Provide the option to add a profile photo and additional details they want your venue to know
            </span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(
                this,
                actions,
                widgetSettings,
                'enableAdditionalProfileInfoConfirmation',
                !widgetSettings.enableAdditionalProfileInfoConfirmation
              )}
              value={!widgetSettings.enableAdditionalProfileInfoConfirmation}
              style={{ margin: '7px 0' }}
            />
          </div>
        </div>
        <div className="settings-height">
          <div className="settings-col settings-height">
            <span className="switch-label">Mandate age consent during checkout</span>
          </div>
          <div className="settings-col settings-height" style={{ width: '100px' }}>
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(this, actions, widgetSettings, 'aboveAgeConsentOn', !widgetSettings.aboveAgeConsentOn)}
              value={!widgetSettings.aboveAgeConsentOn}
              style={{ margin: '7px 0' }}
            />
          </div>
          <div className="settings-col settings-height">
            <Input
              id="age-to-consent"
              type="number"
              inputCss={{ width: 30 }}
              value={widgetSettings.ageToConsent}
              min={1}
              onChangeHandler={_changeField.bind(this, actions, 'ageToConsent')}
              disabled={!widgetSettings.aboveAgeConsentOn}
              validator_type="int_positive"
              validator_message="Enter a number"
            />
          </div>
        </div>

        {widgetSettings.isSmsMarketingEnabled && (
          <div className="settings-height">
            <div className="settings-col settings-height">
              <span className="switch-label">Show Text Marketing Opt-in for Venue</span>
            </div>
            <div className="settings-col settings-height">
              <Switch
                name="hide_event"
                toggle={_changeFieldWithValue.bind(
                  this,
                  actions,
                  widgetSettings,
                  'venueSmsMarketingOn',
                  !widgetSettings.venueSmsMarketingOn
                )}
                value={!widgetSettings.venueSmsMarketingOn}
                style={{ margin: '7px 0' }}
              />
            </div>
          </div>
        )}

        {widgetSettings.isSmsMarketingEnabled && widgetSettings.venueSmsMarketingOn && (
          <div className="settings-height">
            <div className="settings-col inset">
              <CheckBox
                name="venue_specific_sms_marketing_policy_default"
                labelText="Opt-in is checked by default"
                inputCss={styles.RadioCheckBox}
                onClickHandler={_changeFieldWithValue.bind(
                  this,
                  actions,
                  widgetSettings,
                  'isVenueSmsMarketingPolicyDefaultOn',
                  !widgetSettings.isVenueSmsMarketingPolicyDefaultOn
                )}
                disabled={appState.isGetting || appState.isPosting}
                checked={widgetSettings.isVenueSmsMarketingPolicyDefaultOn}
              />
            </div>
          </div>
        )}

        <div className="settings-height">
          <div className="settings-col settings-height">
            <span className="switch-label">Show Email Marketing Opt-in for Venue</span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(
                this,
                actions,
                widgetSettings,
                'venueSpecificMarketingOn',
                !widgetSettings.venueSpecificMarketingOn
              )}
              value={!widgetSettings.venueSpecificMarketingOn}
              style={{ margin: '7px 0' }}
            />
          </div>
        </div>

        {widgetSettings.venueSpecificMarketingOn && (
          <div className="settings-height">
            <div className="settings-col inset">
              <CheckBox
                name="venue_specific_marketing_policy_default"
                labelText="Opt-in is checked by default"
                inputCss={styles.RadioCheckBox}
                onClickHandler={_changeFieldWithValue.bind(
                  this,
                  actions,
                  widgetSettings,
                  'isVenueSpecificMarketingPolicyDefaultOn',
                  !widgetSettings.isVenueSpecificMarketingPolicyDefaultOn
                )}
                disabled={appState.isGetting || appState.isPosting}
                checked={widgetSettings.isVenueSpecificMarketingPolicyDefaultOn}
              />
            </div>
          </div>
        )}

        <div className="settings-height">
          <div className="settings-col settings-height">
            <span className="switch-label">Show Email Marketing Opt-in for Group</span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(
                this,
                actions,
                widgetSettings,
                'venueGroupMarketingOn',
                !widgetSettings.venueGroupMarketingOn
              )}
              value={!widgetSettings.venueGroupMarketingOn}
              style={{ margin: '7px 0' }}
            />
          </div>
        </div>

        {widgetSettings.venueGroupMarketingOn && (
          <div className="settings-height">
            <div className="settings-col inset">
              <CheckBox
                name="venue_group_marketing_policy_default"
                labelText="Opt-in is checked by default"
                inputCss={styles.RadioCheckBox}
                onClickHandler={_changeFieldWithValue.bind(
                  this,
                  actions,
                  widgetSettings,
                  'isVenueGroupMarketingPolicyDefaultOn',
                  !widgetSettings.isVenueGroupMarketingPolicyDefaultOn
                )}
                disabled={appState.isGetting || appState.isPosting}
                checked={widgetSettings.isVenueGroupMarketingPolicyDefaultOn}
              />
            </div>
          </div>
        )}

        <div className="settings-height">
          <div className="settings-col settings-height">
            <span className="switch-label">Opt-in to receive tailored communications</span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(
                this,
                actions,
                widgetSettings,
                'tailoredCommunicationOn',
                !widgetSettings.tailoredCommunicationOn
              )}
              value={!widgetSettings.tailoredCommunicationOn}
              style={{ margin: '7px 0' }}
              disabled={widgetSettings.isTailoredCommunicationPolicyDisabled}
            />
          </div>
        </div>

        {widgetSettings.tailoredCommunicationOn && (
          <div className="settings-height">
            <div className="settings-col inset">
              <CheckBox
                name="tailored_communication_policy_default"
                labelText="Opt-in is checked by default"
                inputCss={styles.RadioCheckBox}
                onClickHandler={_changeFieldWithValue.bind(
                  this,
                  actions,
                  widgetSettings,
                  'isTailoredCommunicationPolicyDefaultOn',
                  !widgetSettings.isTailoredCommunicationPolicyDefaultOn
                )}
                disabled={appState.isGetting || appState.isPosting}
                checked={widgetSettings.isTailoredCommunicationPolicyDefaultOn}
              />
            </div>
          </div>
        )}

        {(widgetSettings.remindersSmsEnabled || widgetSettings.smsBookingNotificationEnabled) &&
          widgetSettings.showSmsOptInSettingsForTwilioUs && (
            <>
              <div className="settings-height">
                <div className="settings-col settings-height">
                  <span className="switch-label">Show opt-in for receiving SMS about reservations</span>
                </div>
                <div className="settings-col settings-height">
                  <Switch disabled name="hide_event" value={!widgetSettings.displayReservationSmsOptIn} style={{ margin: '7px 0' }} />
                </div>
              </div>
              {widgetSettings.displayReservationSmsOptIn && (
                <div className="settings-height">
                  <div className="settings-col settings-height">
                    <span className="switch-label sms_opt_in_policy_default" style={{ marginLeft: '15px' }}>
                      Checked by Default
                    </span>
                  </div>
                  <div className="settings-col settings-height">
                    <Switch
                      name="hide_event"
                      toggle={_changeFieldWithValue.bind(
                        this,
                        actions,
                        widgetSettings,
                        'reservationSmsOptInOn',
                        !widgetSettings.reservationSmsOptInOn
                      )}
                      value={!widgetSettings.reservationSmsOptInOn}
                      style={{ margin: '7px 0' }}
                    />
                  </div>
                </div>
              )}
            </>
          )}

        <div className="settings-height">
          <div className="settings-col settings-height">
            <span className="switch-label">Mandate custom policy agreement to book</span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(
                this,
                actions,
                widgetSettings,
                'customCheckoutPolicyOn',
                !widgetSettings.customCheckoutPolicyOn
              )}
              value={!widgetSettings.customCheckoutPolicyOn}
              style={{ margin: '7px 0' }}
            />
          </div>
        </div>

        {widgetSettings.customCheckoutPolicyOn && (
          <div className="settings-height">
            <div className="settings-col settings-height">
              <span className="switch-label" style={{ marginLeft: '15px' }}>
                Checked by Default
              </span>
            </div>
            <div className="settings-col settings-height">
              <Switch
                name="hide_event"
                toggle={_changeFieldWithValue.bind(
                  this,
                  actions,
                  widgetSettings,
                  'isDefaultCustomCheckoutPolicyOn',
                  !widgetSettings.isDefaultCustomCheckoutPolicyOn
                )}
                value={!widgetSettings.isDefaultCustomCheckoutPolicyOn}
                style={{ margin: '7px 0' }}
              />
            </div>
          </div>
        )}

        <div className="settings-height">
          <div className="settings-col settings-height">
            <span className="switch-label">Show special attention message</span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(
                this,
                actions,
                widgetSettings,
                'showSpecialAttentionMessage',
                !widgetSettings.showSpecialAttentionMessage
              )}
              value={!widgetSettings.showSpecialAttentionMessage}
              style={{ margin: '7px 0' }}
            />
          </div>
        </div>
        <div className="settings-height">
          <div className="settings-col settings-height">
            <span className="switch-label">Enable reCAPTCHA</span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(this, actions, widgetSettings, 'recaptchaOn', !widgetSettings.recaptchaOn)}
              value={!widgetSettings.recaptchaOn}
              style={{ margin: '7px 0' }}
            />
          </div>
        </div>

        {widgetSettings.reservationHoldEnabled && (
          <div className="input-height">
            {appState.errors.reservationHoldTimeMins && <div style={styles.ErrorMessage}>Text field should not be empty</div>}
            <Input
              labelText="Minutes to complete reservation"
              name="reservation_hold_time_mins"
              type="number"
              inputCss={appState.errors.reservationHoldTimeMins ? { ...styles.SmallInput, ...styles.ErrorField } : styles.SmallInput}
              value={widgetSettings.reservationHoldTimeMins}
              min={0}
              onChangeHandler={_changeField.bind(this, actions, 'reservationHoldTimeMins')}
              validator_type="int_positive"
              validator_message="Enter a number"
              disabled={appState.isGetting || appState.isPosting}
            />
          </div>
        )}

        <RadioList
          field="birthdayType"
          selectedValue={widgetSettings.birthdayType}
          onClick={_changeRadioField.bind(this, actions)}
          listLabel="Birthday Input"
          options={[
            { value: 'Hidden', label: 'Hidden' },
            { value: 'Required', label: 'Required' },
            { value: 'Optional', label: 'Optional' },
          ]}
          customRadioListLabel={css`
            color: #aaa;
            font-size: 12px;
            font-weight: 700;
            font-family: Roboto, sans-serif;
            margin: 10px 0px 5px;
          `}
          customRadioIcon={css`
            width: 20px;
            height: 20px;
          `}
          customRadioLabel={css`
            font-weight: 400;
            line-height: 20px;
            color: #aaa;
            font-size: 14px;
            padding: 8px 0 0 4px;
          `}
          customRadioWrapper={css`
            margin-bottom: 4px;
          `}
          customRadioCheck={css`
            width: 10px;
            height: 10px;
            margin: 3px;
            background-color: #347baf;
          `}
        />

        <RadioList
          field="postalCodeType"
          selectedValue={widgetSettings.postalCodeType}
          onClick={_changeRadioField.bind(this, actions)}
          listLabel="Postal Code Input"
          options={[
            { value: 'Hidden', label: 'Hidden' },
            { value: 'Required', label: 'Required' },
            { value: 'Optional', label: 'Optional' },
          ]}
          customRadioListLabel={css`
            color: #aaa;
            font-size: 12px;
            font-weight: 700;
            font-family: Roboto, sans-serif;
            margin: 10px 0px 5px;
          `}
          customRadioIcon={css`
            width: 20px;
            height: 20px;
          `}
          customRadioLabel={css`
            font-weight: 400;
            line-height: 20px;
            color: #aaa;
            font-size: 14px;
            padding: 8px 0 0 4px;
          `}
          customRadioWrapper={css`
            margin-bottom: 4px;
          `}
          customRadioCheck={css`
            width: 10px;
            height: 10px;
            margin: 3px;
            background-color: #347baf;
          `}
        />

        <RadioList
          field="salutationType"
          selectedValue={widgetSettings.salutationType}
          onClick={_changeRadioField.bind(this, actions)}
          listLabel="Salutation Input"
          options={[
            { value: 'Hidden', label: 'Hidden' },
            { value: 'Required', label: 'Required' },
            { value: 'Optional', label: 'Optional' },
          ]}
          customRadioListLabel={css`
            color: #aaa;
            font-size: 12px;
            font-weight: 700;
            font-family: Roboto, sans-serif;
            margin: 10px 0px 5px;
          `}
          customRadioIcon={css`
            width: 20px;
            height: 20px;
          `}
          customRadioLabel={css`
            font-weight: 400;
            line-height: 20px;
            color: #aaa;
            font-size: 14px;
            padding: 8px 0 0 4px;
          `}
          customRadioWrapper={css`
            margin-bottom: 4px;
          `}
          customRadioCheck={css`
            width: 10px;
            height: 10px;
            margin: 3px;
            background-color: #347baf;
          `}
        />
      </div>
      <div style={styles.FormSection}>
        <div className="inline">
          <Button style={styles.SaveButton} label="Save" onClick={_save.bind(this, actions, appState, widgetSettings)} />
        </div>
        <div className="float-end" />
      </div>
    </form>
  )
}

TextEntry.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  /* eslint-disable react/forbid-prop-types */
  widgetSettings: PropTypes.object,
  appState: PropTypes.object,
  actions: PropTypes.object,
  /* eslint-enable react/forbid-prop-types */
  width: PropTypes.number,
  marginLeft: PropTypes.number,
}

WidgetSettingsForm.propTypes = {
  MEDIA_URL: PropTypes.string,
  /* eslint-disable react/forbid-prop-types */
  widgetSettings: PropTypes.object,
  appState: PropTypes.object,
  actions: PropTypes.object,
  /* eslint-enable react/forbid-prop-types */
}
